import React from "react";

const ProjectContainer = ({
  isLoaded,
  onMouseEnter,
  onMouseLeave,
  children,
}) => (
  <div
    className={`project-container ${isLoaded ? "loaded" : ""}`}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    {children}
  </div>
);

export default ProjectContainer;
