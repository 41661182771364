import React, { useState, useEffect, useMemo } from "react";
import MainLayout from "./layouts/MainLayout";
import Welcome from "./components/Welcome/Welcome";
import Home from "./components/Home/Home";
import Info from "./components/Info/Info";
import Contact from "./components/Contact/Contact";
import useAuraState from "./hooks/useAuraState";
import "./styles/global.scss";
import "./App.css";
import Projects from "./components/Projects/Projects";

const loadAllData = async () => {
  // Simulate loading data with a delay
  await new Promise((resolve) => setTimeout(resolve, 3000));

  // Add actual data loading logic here
  // For example:
  // await Promise.all([
  //   fetch('/api/projects').then(res => res.json()),
  //   fetch('/api/info').then(res => res.json()),
  //   // ... other data fetching
  // ]);
};

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [currentSection, setCurrentSection] = useState("home");
  const sections = useMemo(() => ["home", "projects", "info", "contact"], []);
  const [auraState, updateAuraState] = useAuraState();

  useEffect(() => {
    loadAllData(); // Start loading data immediately
  }, []);

  useEffect(() => {
    let wheelTimeout;
    let touchStartY = 0;
    const minSwipeDistance = 50; // Minimum distance for a swipe to be registered

    const changeSection = (direction) => {
      setCurrentSection((prevSection) => {
        const currentIndex = sections.indexOf(prevSection);
        const newIndex = Math.max(
          0,
          Math.min(sections.length - 1, currentIndex + direction)
        );
        return sections[newIndex];
      });
    };

    const handleWheel = (event) => {
      event.preventDefault();

      clearTimeout(wheelTimeout);
      wheelTimeout = setTimeout(() => {
        const direction = event.deltaY > 0 ? 1 : -1;
        changeSection(direction);
      }, 50); // Debounce time
    };

    const handleTouchStart = (event) => {
      touchStartY = event.touches[0].clientY;
    };

    const handleTouchEnd = (event) => {
      const touchEndY = event.changedTouches[0].clientY;
      const distance = touchStartY - touchEndY;

      if (Math.abs(distance) > minSwipeDistance) {
        const direction = distance > 0 ? 1 : -1;
        changeSection(direction);
      }
    };

    if (!isLoading) {
      window.addEventListener("wheel", handleWheel, { passive: false });
      window.addEventListener("touchstart", handleTouchStart);
      window.addEventListener("touchend", handleTouchEnd);

      return () => {
        window.removeEventListener("wheel", handleWheel);
        window.removeEventListener("touchstart", handleTouchStart);
        window.removeEventListener("touchend", handleTouchEnd);
      };
    }
  }, [sections, isLoading]);

  const renderSection = () => {
    switch (currentSection) {
      case "home":
        return <Home updateAuraState={updateAuraState} />;
      case "projects":
        return <Projects updateAuraState={updateAuraState} />;
      case "info":
        return <Info updateAuraState={updateAuraState} />;
      case "contact":
        return <Contact />;
      default:
        return <Home updateAuraState={updateAuraState} />;
    }
  };

  const renderContent = () => {
    if (isLoading) {
      return <Welcome setIsLoading={setIsLoading} />;
    } else {
      return (
        <MainLayout
          currentSection={currentSection}
          setCurrentSection={setCurrentSection}
          auraState={auraState}
          updateAuraState={updateAuraState}
          currentComponentIndex={sections.indexOf(currentSection) + 1}
          totalComponents={sections.length}
        >
          {renderSection()}
        </MainLayout>
      );
    }
  };

  return <div className="App">{renderContent()}</div>;
}

export default App;
