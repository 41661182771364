import React from "react";
import CornerLines from "../CornerLines/CornerLines";

const Frame = () => {
  return (
    <div className="frame">
      <CornerLines position="frame__corner frame__corner--top-left" />
      <CornerLines position="frame__corner frame__corner--top-right" />
      <CornerLines position="frame__corner frame__corner--bottom-left" />
      <CornerLines position="frame__corner frame__corner--bottom-right" />
    </div>
  );
};

export default Frame;
