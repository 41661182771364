// src/utils/WebGLContextManager.js
import * as THREE from "three";

class WebGLContextManager {
  constructor() {
    this.renderer = null;
    this.scene = null;
    this.camera = null;
    this.animationFrame = null;
  }

  initialize(width, height) {
    if (!this.renderer) {
      this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      this.renderer.setSize(width, height);
    }
    if (!this.scene) {
      this.scene = new THREE.Scene();
    }
    if (!this.camera) {
      this.camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
      this.camera.position.z = 1.2;
    }
  }

  getRenderer() {
    return this.renderer;
  }

  getScene() {
    return this.scene;
  }

  getCamera() {
    return this.camera;
  }

  startAnimation(animateCallback) {
    const animate = () => {
      this.animationFrame = requestAnimationFrame(animate);
      animateCallback();
      this.renderer.render(this.scene, this.camera);
    };
    animate();
  }

  stopAnimation() {
    if (this.animationFrame) {
      cancelAnimationFrame(this.animationFrame);
    }
  }

  dispose() {
    this.stopAnimation();
    if (this.renderer) {
      this.renderer.dispose();
    }
    // Clear the scene
    while (this.scene.children.length > 0) {
      this.scene.remove(this.scene.children[0]);
    }
  }
}

const webGLContextManager = new WebGLContextManager();

export default webGLContextManager;
