import React from "react";
import Arrow from "../Slider/Arrow";

const SlideButton = ({
  direction,
  isLoaded,
  isChanging,
  onClick,
  projectName,
}) => (
  <div
    className={`slide ${direction} ${isLoaded ? "loaded" : ""} ${
      isChanging ? "changing" : ""
    }`}
    onClick={onClick}
  >
    {direction === "prev" && <div className="prev-project">{projectName}</div>}
    <Arrow direction={direction === "prev" ? "left" : "right"} />
    {direction === "next" && <div className="next-project">{projectName}</div>}
  </div>
);

export default SlideButton;
