import React, { useEffect, useState } from "react";
import "../../styles/components/Info.scss";

const Info = ({ updateAuraState }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const handleMouseEnter = () => {
    updateAuraState({ isHovered: true });
  };

  const handleMouseLeave = () => {
    updateAuraState({ isHovered: false });
  };

  return (
    <div
      className={`info ${isLoaded ? "loaded" : ""}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <h1>download my resume</h1>
      <div className="line"></div>
    </div>
  );
};

export default Info;
