// src/components/AuraAnimation/AuraAnimation.js
import React, { useRef, useEffect, useState } from "react";
import * as THREE from "three";
import WebGLContextManager from "../../utils/WebGLContextManager";

const AuraAnimation = ({ auraState, section }) => {
  const mountRef = useRef(null);
  const linesRef = useRef([]);
  const [transitionFactor, setTransitionFactor] = useState(0);

  useEffect(() => {
    const mountNode = mountRef.current;
    const width = mountNode.clientWidth;
    const height = mountNode.clientHeight;

    WebGLContextManager.initialize(width, height);
    const renderer = WebGLContextManager.getRenderer();
    const scene = WebGLContextManager.getScene();
    const camera = WebGLContextManager.getCamera();

    camera.position.z = 5;
    camera.updateProjectionMatrix();

    mountNode.appendChild(renderer.domElement);

    const createLine = (color, yPosition, zPosition) => {
      const points = [];
      const numPoints = 100;
      const lineWidth = 20;
      for (let i = 0; i < numPoints; i++) {
        points.push(
          new THREE.Vector3(
            (i / (numPoints - 1)) * lineWidth - lineWidth / 2,
            0,
            0
          )
        );
      }
      const geometry = new THREE.BufferGeometry().setFromPoints(points);
      const material = new THREE.LineBasicMaterial({
        color: color,
        transparent: true,
        opacity: 0.5,
      });
      const line = new THREE.Line(geometry, material);
      line.position.y = yPosition;
      line.position.z = zPosition;
      scene.add(line);
      return {
        object: line,
        targetPositions: new Float32Array(points.length * 3),
        zOffsets: new Float32Array(points.length).map(
          () => (Math.random() - 0.5) * 2
        ),
      };
    };

    linesRef.current = [
      createLine(0x00ff00, 1, 0),
      createLine(0x0000ff, 0.7, 0.5),
      createLine(0xff0000, 0.4, -0.5),
      createLine(0xffff00, 0.1, 0.25),
      createLine(0xff00ff, 0, -0.25),
      createLine(0x00ffff, -0.1, 0.75),
      createLine(0xffa500, -0.4, -0.75),
      createLine(0x800080, -0.7, 0.6),
      createLine(0x008080, -1, -0.6),
    ];

    const handleResize = () => {
      const width = mountNode.clientWidth;
      const height = mountNode.clientHeight;
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      renderer.setSize(width, height);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      WebGLContextManager.stopAnimation();
      if (mountNode && renderer.domElement) {
        mountNode.removeChild(renderer.domElement);
      }
      linesRef.current.forEach((line) => {
        scene.remove(line.object);
        line.object.geometry.dispose();
        line.object.material.dispose();
      });
    };
  }, []);

  useEffect(() => {
    const targetTransitionFactor = section === "projects" ? 1 : 0;
    const transitionSpeed = 0.02;

    const updateTransition = () => {
      setTransitionFactor((prev) => {
        const diff = targetTransitionFactor - prev;
        if (Math.abs(diff) < 0.001) return targetTransitionFactor;
        return prev + diff * transitionSpeed;
      });
    };

    const intervalId = setInterval(updateTransition, 16);

    return () => clearInterval(intervalId);
  }, [section]);

  useEffect(() => {
    const lerp = (start, end, t) => start * (1 - t) + end * t;

    const animateCallback = () => {
      const time = Date.now() * 0.001;
      const transitionSpeed = 0.05;

      linesRef.current.forEach((line, index) => {
        const positions = line.object.geometry.attributes.position.array;
        for (let i = 0; i < positions.length; i += 3) {
          const x = positions[i];
          const z = lerp(0, line.zOffsets[i / 3], transitionFactor);
          positions[i + 2] = z;

          const amplitude = 0.1;
          const frequency = 1 + index * 0.1;
          const intensity = auraState.intensity || 1;

          let targetY;

          if (section === "home") {
            if (auraState.isHovered) {
              targetY = 0;
            } else {
              targetY =
                Math.sin(x * frequency + (index % 2 === 0 ? time : -time)) *
                amplitude *
                intensity;
            }
          } else if (section === "projects") {
            if (auraState.isHovered) {
              const waveFrequency = 3 + index * 0.5;
              const waveAmplitude = 0.08;
              const rippleEffect = Math.sin(x * 2 + z * 2 + time * 3) * 0.02;
              targetY =
                Math.sin(time * waveFrequency + x * 2 + z * 2) * waveAmplitude +
                rippleEffect +
                Math.cos(time * (index + 1) * 0.5) * 0.03;
            } else {
              targetY =
                Math.cos(x * frequency * 2 + z * frequency + time) *
                amplitude *
                intensity *
                0.5;
            }
          } else if (section === "info") {
            if (auraState.isHovered) {
              const spiralFrequency = 2 + index * 0.3;
              const spiralAmplitude = 0.05;
              const spiralEffect = Math.sin(x * 3 + z * 3 + time * 2) * 0.03;
              targetY =
                Math.sin(time * spiralFrequency + x * 3 + z * 3) *
                  spiralAmplitude +
                spiralEffect +
                Math.cos(time * (index + 1) * 0.7) * 0.02;
            } else {
              targetY =
                Math.sin(x * frequency * 1.5 + z * frequency * 1.5 + time) *
                amplitude *
                intensity *
                0.6;
            }
          } else {
            targetY =
              Math.sin(x * frequency + time) * amplitude * intensity * 0.75;
          }

          line.targetPositions[i + 1] = targetY;
          positions[i + 1] = lerp(
            positions[i + 1],
            line.targetPositions[i + 1],
            transitionSpeed
          );
        }
        line.object.geometry.attributes.position.needsUpdate = true;
      });
    };

    WebGLContextManager.startAnimation(animateCallback);

    return () => {
      WebGLContextManager.stopAnimation();
    };
  }, [auraState, section, transitionFactor]);

  return (
    <div
      ref={mountRef}
      style={{
        width: "100vw",
        height: "100vh",
        top: 0,
        left: 0,
        zIndex: -1,
      }}
    />
  );
};

export default AuraAnimation;
