import React, { useState, useEffect, useCallback } from "react";
import ProjectDetails from "../ProjectDetails/ProjectDetails";
import SlideButton from "../SlideButton/SlideButton";
import ProjectContainer from "../ProjectContainer/ProjectContainer";
import { projectsData } from "../../data/ProjectsData/projectsData";
import "../../styles/components/Projects.scss";

const Projects = ({ updateAuraState }) => {
  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isChanging, setIsChanging] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const getIndex = useCallback(
    (index, offset) =>
      (index + offset + projectsData.length) % projectsData.length,
    []
  );

  const handleProjectChange = useCallback(
    (indexGetter) => {
      if (isButtonDisabled) return;
      setIsButtonDisabled(true);
      setIsChanging(true);
      setIsTransitioning(true);

      const transitionProject = () => {
        setIsClosing(true);
        setTimeout(() => {
          setIsVisible(false);
          const newIndex = indexGetter(currentProjectIndex);
          setCurrentProjectIndex(newIndex);

          setTimeout(() => {
            setIsClosing(false);
            setIsTransitioning(false);
            setIsVisible(true);
            setIsChanging(false);
            setIsButtonDisabled(false);
          }, 50);
        }, 500);
      };

      setTimeout(transitionProject, 500);
    },
    [currentProjectIndex, isButtonDisabled]
  );

  const handlePrevClick = useCallback(
    () => handleProjectChange((index) => getIndex(index, -1)),
    [handleProjectChange, getIndex]
  );

  const handleNextClick = useCallback(
    () => handleProjectChange((index) => getIndex(index, 1)),
    [handleProjectChange, getIndex]
  );

  useEffect(() => {
    const timer = setTimeout(() => setIsLoaded(true), 100);
    return () => clearTimeout(timer);
  }, []);

  const handleMouseEnter = useCallback(
    () => updateAuraState({ isHovered: true }),
    [updateAuraState]
  );
  const handleMouseLeave = useCallback(
    () => updateAuraState({ isHovered: false }),
    [updateAuraState]
  );

  const prevProjectIndex = getIndex(currentProjectIndex, -1);
  const currentProject = projectsData[currentProjectIndex];
  const nextProjectIndex = getIndex(currentProjectIndex, 1);

  return (
    <div className={`project-slider ${isLoaded ? "loaded" : ""}`}>
      <SlideButton
        direction="prev"
        isLoaded={isLoaded}
        isChanging={isChanging}
        onClick={handlePrevClick}
        projectName={projectsData[prevProjectIndex].name}
      />
      <ProjectContainer
        isLoaded={isLoaded}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <ProjectDetails
          name={currentProject.name}
          createdDate={currentProject.createdDate}
          roles={currentProject.roles}
          url={currentProject.url}
          isVisible={isVisible}
          isTransitioning={isTransitioning}
          isClosing={isClosing}
        />
      </ProjectContainer>
      <SlideButton
        direction="next"
        isLoaded={isLoaded}
        isChanging={isChanging}
        onClick={handleNextClick}
        projectName={projectsData[nextProjectIndex].name}
      />
    </div>
  );
};

export default Projects;
