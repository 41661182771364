import React, { useState, useEffect } from "react";
import Header from "../components/Header/Header";
import Navbar from "../components/Navbar/Navbar";
import Frame from "../components/Frame/Frame";
import AuraAnimation from "../components/AuraAnimation/AuraAnimation";
import "../styles/components/MainLayout.scss";

function MainLayout({
  children,
  currentSection,
  setCurrentSection,
  auraState,
  updateAuraState,
}) {
  const [isEntering, setIsEntering] = useState(true);

  useEffect(() => {
    setIsEntering(false);
  }, []);

  return (
    <div className={`main-layout ${isEntering ? "main-layout--entering" : ""}`}>
      <div className="main-layout__aura-container">
        <AuraAnimation auraState={auraState} section={currentSection} />
      </div>
      <Frame />
      <Header className="main-layout__header" />
      <main className="main-layout__content-container">{children}</main>
      <div className="main-layout__navbar-container">
        <Navbar
          setCurrentSection={setCurrentSection}
          currentSection={currentSection}
        />
      </div>
      <footer className="main-layout__footer">
        Reproduction, reposting or modification of the content of this site is
        prohibited.
      </footer>
    </div>
  );
}

export default MainLayout;
