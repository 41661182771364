import React from "react";
import "../../styles/components/ProjectDetails.scss";

const ProjectDetails = ({
  name,
  createdDate,
  roles,
  url,
  isVisible,
  isTransitioning,
  isClosing,
}) => {
  const date = new Date(createdDate);
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();

  return (
    <div
      className={`project ${isVisible ? "visible" : ""} ${
        isTransitioning ? "transitioning" : ""
      } ${isClosing ? "closing" : ""}`}
    >
      <div className="project-info left">
        <span className="date-month">{month}</span>
        <span className="date-year">{year}</span>
      </div>
      <div className="project-name">
        <h3>
          <a href={url} target="_blank" rel="noopener noreferrer">
            {name}
          </a>
        </h3>
      </div>
      <div className="project-info right">
        {roles.map((role, index) => (
          <span key={index} className="role">
            {role}
          </span>
        ))}
      </div>
    </div>
  );
};

export default ProjectDetails;
