import React from "react";

const Arrow = ({ direction = "right" }) => {
  return (
    <div className={`arrow ${direction}`}>
      <div className="line"></div>
    </div>
  );
};

export default Arrow;
