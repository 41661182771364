import React from "react";
import "../../styles/components/CornerLines.scss";

const CornerLines = ({ position }) => {
  return (
    <div className={`corner-lines ${position}`}>
      <div className="corner-lines__line corner-lines__line--horizontal"></div>
      <div className="corner-lines__line corner-lines__line--vertical"></div>
    </div>
  );
};

export default CornerLines;
