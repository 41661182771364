import React, { useEffect, useState } from "react";
import "../../styles/components/Contact.scss";
import IsometricIcon from "../IsometricIcon/IsometricIcon";

const contactDetail = [
  {
    name: "github",
    url: "https://github.com/kiarashjv",
    icon: <IsometricIcon color={0x333333} type="github" />,
  },
  {
    name: "linkedin",
    url: "https://www.linkedin.com/in/kiarash-javadian-a42b55205",
    icon: <IsometricIcon color={0x0077b5} type="linkedin" />,
  },
  {
    name: "email",
    url: "mailto:dev.kiarash@gmail.com",
    icon: <IsometricIcon color={0xd44638} type="email" />,
  },
];

const Contact = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <div className={`contact ${isLoaded ? "loaded" : ""}`}>
      {contactDetail.map((contact, index) => (
        <a
          key={contact.name}
          href={contact.url}
          target="_blank"
          rel="noopener noreferrer"
          className="contact-item"
          style={{ animationDelay: `${index * 0.1}s` }}
        >
          <span className="contact-name">{contact.name}</span>
        </a>
      ))}
    </div>
  );
};

export default Contact;
