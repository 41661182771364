import React from "react";
import "../../styles/components/Navbar.scss";

const Navbar = ({ setCurrentSection, currentSection }) => {
  const sections = ["home", "projects", "info", "contact"];

  return (
    <nav className="x-navbar">
      <div className="x-navbar__line">
        {sections.map((section) => (
          <div
            key={section}
            className={`x-navbar__line-segment ${
              currentSection === section ? "x-navbar__line-segment--active" : ""
            }`}
          ></div>
        ))}
      </div>
      {sections.map((section) => (
        <button
          key={section}
          className={`x-navbar__item ${
            currentSection === section ? "x-navbar__item--active" : ""
          }`}
          onClick={() => setCurrentSection(section)}
        >
          {section.charAt(0).toUpperCase() + section.slice(1)}
        </button>
      ))}
    </nav>
  );
};

export default Navbar;
