// src/components/IsometricIcon/IsometricIcon.js
import React, { useRef, useEffect, useState } from "react";
import * as THREE from "three";

const rendererPool = [];

const getRenderer = (width, height) => {
  if (rendererPool.length > 0) {
    const renderer = rendererPool.pop();
    renderer.setSize(width, height);
    return renderer;
  }
  return new THREE.WebGLRenderer({ alpha: true, antialias: true });
};

const releaseRenderer = (renderer) => {
  if (rendererPool.length < 5) {
    // Limit pool size
    rendererPool.push(renderer);
  } else {
    renderer.dispose();
  }
};

const IsometricIcon = ({ color, shape }) => {
  const mountRef = useRef(null);
  const rendererRef = useRef(null);
  const sceneRef = useRef(null);
  const meshRef = useRef(null);
  const frameIdRef = useRef(null);
  const [size] = useState({ width: 200, height: 200 });

  useEffect(() => {
    const { width, height } = size;
    const currentMount = mountRef.current;

    if (!sceneRef.current) {
      sceneRef.current = new THREE.Scene();
    }

    if (!rendererRef.current) {
      rendererRef.current = getRenderer(width, height);
      currentMount.appendChild(rendererRef.current.domElement);
    }

    const scene = sceneRef.current;
    const renderer = rendererRef.current;

    const camera = new THREE.OrthographicCamera(
      width / -10,
      width / 10,
      height / 10,
      height / -10,
      0.1,
      1000
    );

    camera.position.set(20, 20, 20);
    camera.lookAt(2, 0, 0);

    const material = new THREE.MeshBasicMaterial({
      color: color,
      wireframe: true,
    });

    let geometry;
    switch (shape) {
      case "cube":
        geometry = new THREE.BoxGeometry(10, 10, 10);
        break;
      case "sphere":
        geometry = new THREE.SphereGeometry(6, 32, 32);
        break;
      case "cone":
        geometry = new THREE.ConeGeometry(6, 10, 32);
        break;
      case "cylinder":
        geometry = new THREE.CylinderGeometry(4, 4, 10, 32);
        break;
      default:
        geometry = new THREE.BoxGeometry(10, 10, 10);
    }

    if (meshRef.current) {
      scene.remove(meshRef.current);
      meshRef.current.geometry.dispose();
      meshRef.current.material.dispose();
    }

    meshRef.current = new THREE.Mesh(geometry, material);
    scene.add(meshRef.current);

    const animate = () => {
      frameIdRef.current = requestAnimationFrame(animate);
      if (meshRef.current) {
        meshRef.current.rotation.x += 0.01;
        meshRef.current.rotation.y += 0.01;
      }
      renderer.render(scene, camera);
    };

    animate();

    return () => {
      cancelAnimationFrame(frameIdRef.current);
      if (meshRef.current) {
        scene.remove(meshRef.current);
        meshRef.current.geometry.dispose();
        meshRef.current.material.dispose();
        meshRef.current = null;
      }
      if (rendererRef.current) {
        releaseRenderer(rendererRef.current);
        if (currentMount.contains(rendererRef.current.domElement)) {
          currentMount.removeChild(rendererRef.current.domElement);
        }
        rendererRef.current = null;
      }
    };
  }, [color, shape, size]);

  return (
    <div
      ref={mountRef}
      style={{ width: `${size.width}px`, height: `${size.height}px` }}
    />
  );
};

export default IsometricIcon;
