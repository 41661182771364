// src/hooks/useAuraState.js
import { useState, useCallback } from "react";

const useAuraState = () => {
  const [auraState, setAuraState] = useState({
    isHovered: false,
    intensity: 1,
    // Add any other properties you want to control
  });

  const updateAuraState = useCallback((newState) => {
    setAuraState((prevState) => ({ ...prevState, ...newState }));
  }, []);

  return [auraState, updateAuraState];
};

export default useAuraState;
