import React, { useState, useEffect } from "react";
import "../../styles/components/Welcome.scss";

const Welcome = ({ setIsLoading }) => {
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [isExiting, setIsExiting] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          setIsExiting(true);
          setTimeout(() => setIsLoading(false), 1000); // Delay to allow exit animation
          return 100;
        }
        return prevProgress + 1;
      });
    }, 50);

    return () => clearInterval(interval);
  }, [setIsLoading]);

  return (
    <div className={`welcome ${isExiting ? "welcome--exiting" : ""}`}>
      <h1 className="welcome__title">Kiarash Javadian</h1>
      <h4 className="welcome__subtitle">Portfolio</h4>
      <div className="welcome__loading-bar">
        <div
          className="welcome__loading-progress"
          style={{ width: `${loadingProgress}%` }}
        ></div>
      </div>
    </div>
  );
};

export default Welcome;
