import React from "react";
import "../../styles/components/Header.scss";

const Header = ({ className }) => {
  return (
    <header className={`header ${className || ""}`}>
      <h3 className="header__subtitle">Developer & Designer</h3>
      <h1 className="header__title">Kiarash Javadian</h1>
    </header>
  );
};

export default Header;
