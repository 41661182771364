// src/components/Home/Home.js
import React from "react";
import "../../styles/components/Home.scss";

const Home = ({ updateAuraState }) => {
  const handleMouseEnter = () => {
    updateAuraState({ isHovered: true });
  };

  const handleMouseLeave = () => {
    updateAuraState({ isHovered: false });
  };

  return (
    <div className="home">
      <div className="home__content">
        <p className="home__text-line home__text-line--thin">
          <span className="home__text-span">as I have always said</span>
        </p>
        <h2
          className="home__text-line home__text-line--bold"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <span className="home__text-span">nothing more than human</span>
        </h2>
        <p className="home__text-line home__text-line--thin">
          <span className="home__text-span">would be happy to help me</span>
        </p>
        <p className="home__text-line home__text-line--thin">
          <span className="home__text-span">find my way.</span>
        </p>
      </div>
    </div>
  );
};

export default Home;
